<template>
  <v-card
    class="brand-edition"
    @dragover.prevent="toggleHighlight(true)"
    @dragenter="toggleHighlight(true)"
    @dragleave="toggleHighlight(false)"
  >
    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      top
      height="2"
    />
    <v-card-title class="text-subtitle-1 text-overline pr-12">
      {{ title }}
    </v-card-title>
    <v-btn
      fab
      icon
      small
      absolute
      top
      right
      :disabled="loading"
      color="grey"
      class="mt-7 mr-n2"
      @click="close(true)"
    >
      <v-icon>
        {{ icons.close }}
      </v-icon>
    </v-btn>
    <v-divider />
    <v-card-text class="brand-content py-8 px-0 scrollable">
      <v-window
        :value="level"
      >
        <v-window-item class="px-6">
          <!-- brand -->
          <v-card
            outlined
            class="d-flex align-center mb-6 pr-2"
          >
            <v-text-field
              v-model="controller.title.data"
              :rules="controller.title.rules"
              label="Nome da Marca"
              solo
              flat
              full-width
              hide-details
              ref="title"
              :disabled="disabled||controller.title.disabled||loading"
              class="flex-grow-1 pl-1"
            />
            <v-menu
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="admin&&controller.category.data!=null"
                  text
                  plain
                  small
                  right
                  :disabled="disabled||controller.category.disabled||loading"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ controller.category.options[controller.category.data].text }}
                  <v-icon small right>{{ icons.menu }}</v-icon>
                </v-btn>
              </template>
              <!-- Options -->
              <v-list dense>
                <v-list-item
                  v-for="(category) in controller.category.options"
                  :key="'category-'+category.value"
                  class="text-overline"
                  @click="updateField(['category', 'data'], category.value)"
                >
                  {{ category.text }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card>
          <span 
            v-if="validator.title!=true"
            class="error--text text-caption ma-1 ml-3 mb-4 d-inline-block"
          >
            {{ validator.title }}
          </span>

          <v-card
            outlined
            height="56"
            class="logo-control py-2 pl-4 pr-2 d-flex align-center"
            :class="['elevation-'+(controller.logotype.highlight ? '2' : 0)]"
            @drop.prevent="uploadLogo($event, true)"
            @click="selectLogo"
          >
            <h2 
              v-if="controller.logotype.highlight"
              class="hint text-overline primary--text"
            >
              Solte o arquivo aqui
            </h2>
            <img
              v-else-if="hasLogo"
              ref="logo"
              :src="controller.logotype.data"
              class="logo"
            >
            <h2 
              v-else
              class="hint text-overline"
            >
              Logomarca
            </h2>
            <v-spacer />
            <v-btn
              text
              small
              plain
              :disabled="disabled||controller.logotype.disabled||loading"
              class="px-2"
              @click.stop="selectLogo"
            >
              Selecionar
            </v-btn>
            <input 
              :value="controller.logotype.file"
              type="file" 
              ref="upload-field" 
              class="d-none"
              @change="uploadLogo($event, false)"
            >
          </v-card>
          <span 
            v-if="controller.logotype.error==null"
            class="text-caption text-disabled ma-1 ml-3 mb-4 d-inline-block"
          >
            Formato: PNG com fundo transparente ou branco
          </span>
          <span 
            v-else
            class="error--text text-caption text-disabled ma-1 ml-3 mb-4 d-inline-block"
            v-html="controller.logotype.error"
          />

          <v-card
            outlined
            class="advertisers mb-6"
          >
            <v-subheader 
              class="text-overline black--text pl-4 pr-2"
              style="height: 56px;"
            >
              Anunciantes
              <v-tooltip 
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    plain
                    small
                    right
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>{{ icons.help }}</v-icon>
                  </v-btn>
                </template>
                <span class="text-caption">Adicione aqui Contratos, Agências e Pessoas</span>
              </v-tooltip>
              <v-spacer />
              <v-btn
                text
                small
                plain
                :disabled="disabled||controller.advertisers.disabled||loading"
                class="px-2"
                @click="addAdvertiser"
              >
                Adicionar
              </v-btn>
            </v-subheader>
            <v-divider v-if="hasAdvertisers" />
            
            <v-list
              v-if="hasAdvertisers"
              :disabled="disabled||controller.advertisers.disabled||loading"
            >
              <v-list-item
                v-for="(advertiser, a) in controller.advertisers.data"
                :key="'advertiser-'+a"
                class="pr-3"
                @click="selectAdvertiser(a)"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-medium mb-2">
                    {{ advertiser | advertiserTitle(data) }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption">
                    {{ advertiser | advertiserBrief }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon size="20" color="grey">{{ icons.next }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>

          <v-card
            outlined
            class="cities"
          >
            <v-subheader 
              class="text-overline black--text pl-4 pr-2"
              style="height: 56px;"
            >
              Cidades Habilitadas
            </v-subheader>
            <v-divider v-if="hasAdvertisers" />
            <v-list
              flat
              dense
              :disabled="disabled||controller.cities.disabled||loading"
              max-height="180"
              class="cities-field scrollable py-1"
            >
              <v-list-item-group
                v-model="controller.cities.data"
                mandatory
                multiple
                color="primary"
              >
                <v-list-item
                  v-for="(city, c) in cities"
                  :value="city.id"
                  :key="'city-'+c"
                  class="pr-3"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="20">
                        {{ active ? icons.checked : icons.unchecked }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline">
                        {{ city.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-window-item>

        <v-window-item class="px-6">
          <!-- advertiser -->
          <advertisers-control
            :selected="controller.advertisers.selected"
            :data="controller.advertisers.data[controller.advertisers.selected]"
            :brand="controller.title.data"
            :agency="isAgency"
            :agencies="agencies"
            :admin="admin"
            :users="users"
            :roles="user.roles"
            :disabled="disabled||controller.advertisers.disabled||loading"
            class="py-2"
            @add-contract="addContract"
            @select-contract="selectContract"
            @delete-advertiser="deleteAdvertiser"
            @update="(value) => updateField(['advertisers', 'data', controller.advertisers.selected], value)"
          />
        </v-window-item>

        <v-window-item class="px-6">
          <!-- contract -->
          <contracts-control
            :selected="controller.advertisers.contract"
            :data="contract"
            :advertiser="controller.advertisers.selected"
            :disabled="disabled||controller.advertisers.disabled||loading"
            class="py-2"
            @select-contract="selectContract"
            @delete-contract="deleteContract"
            @update="(value) => updateField(['advertisers', 'data', controller.advertisers.selected, 'contracts', controller.advertisers.contract], value)"
          />
        </v-window-item>
      </v-window>
    </v-card-text>
    <v-divider />
    <v-expand-transition>
      <v-card-actions 
        class="pr-6 py-4"
      >
        <v-btn
          text
          :color="level>0 ? 'primary' : 'grey'"
          :disabled="loading"
          @click="close"
        >
          <v-icon 
            v-if="level>0"
            left
          >{{ icons.back }}</v-icon>
          {{ level>0 || !(updated&&changed) ? 'Voltar' : 'Cancelar' }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-show="saved!=null"
          depressed
          color="primary"
          :disabled="disabled||!changed||!validated"
          :loading="loading"
          class="px-4"
          @click="save"
        >
          <v-icon 
            v-show="saved"
            left
            class="ml-0"
          >
            {{ icons.check }}
          </v-icon>
          {{ updated&&!changed&&saved ? 'Salvo' : 'Salvar' }}
        </v-btn>
      </v-card-actions>
    </v-expand-transition>

    <v-overlay 
      :value="confirm"
      absolute
      opacity=".8"
      class="confirm-overlay"
      @click.native="confirm = false;"
    >
      <v-card
        elevation="8"
        light
        max-width="64%"
        class="rounded-lg"
        style="margin: 0 auto;"
      >
        <v-card-title>
          Descartar alterações?
        </v-card-title>
        <v-card-text>
          <p class="text-body-2">
            As alterações feitas ainda não foram salvas. Deseja descarta-las?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="error"
            class="px-4"
            @click="close(true, true)"
          >
            Descartar
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            class="px-4"
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-card>
</template>

<style lang="scss">

  .brand-edition .brand-content {
    max-height: 72vh;
  }

  .brand-edition .logo {
    max-height: 26px;
    -webkit-filter: invert(72%);
    filter: invert(72%);
  }

  .brand-edition .v-select__selections input {
    width: 0 !important;
    min-width: 0 !important;
  }

  .brand-edition .agency-field input, .brand-edition .agency-field label, .brand-edition .agency-field .v-text-field__prefix {
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.1725em !important;
    text-transform: uppercase !important;
    font-size: 0.75rem !important;
  }

</style>

<script>

  import { 
    mdiClose,
    mdiMenuDown,
    mdiDeleteOutline,
    mdiHelpCircle,
    mdiChevronRight,
    mdiArrowLeft,
    mdiCheck,
    mdiCheckboxMarked,
    mdiCheckboxBlankOutline
  } from '@mdi/js';
  import numeral from 'numeral'
  import {VMoney} from 'v-money'
  const moment = require('moment');

  export default {
    props: {
      id: {
        type: [String, Number],
        default: null
      },
      data: {
        type: [String, Object],
        default: () => ''
      },
      agencies: {
        type: Array,
        default: () => []
      },
      users: {
        type: Array,
        default: () => []
      },
      cities: {
        type: Array,
        default: () => []
      },
      user: {
        type: Object,
        default: () => null
      },
      admin: {
        type: Boolean,
        default: false
      },
      updated: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
    },
    
    data: () => ({
      icons: {
        close: mdiClose,
        menu: mdiMenuDown,
        delete: mdiDeleteOutline,
        help: mdiHelpCircle,
        next: mdiChevronRight,
        back: mdiArrowLeft,
        check: mdiCheck,
        checked: mdiCheckboxMarked,
        unchecked: mdiCheckboxBlankOutline
      },
      controller: {
        title: {
          data: '',
          changed: false,
          disabled: false,
          loading: false,
          rules: [v => v!=null && v!='' || 'Campo obrigatório'],
        },
        category: {
          data: 'GM',
          changed: false,
          disabled: false,
          loading: false,
          options: {
            GM: {
              text: 'Grande marca',
              value: 'GM',
            },
            ML: {
              text: 'Marca Local',
              value: 'ML',
            },
            CAL: {
              text: 'Calhau',
              value: 'CAL',
            },
            GOV: {
              text: 'Governo',
              value: 'GOV',
            },
          },
        },
        cities: {
          data: [],
          changed: false,
          disabled: false,
          loading: false,
        },
        logotype: {
          data: null,
          changed: false,
          disabled: false,
          loading: false,
          error: null,
          highlight: false,
          file: null,
          format: {
            allowed: ['image/png', 'image/svg+xml'], // 'image/jpeg', 'image/svg+xml'
            max: 500000,
            success: false,
          },
        },
        advertisers: {
          data: {},
          selected: null,
          contract: null,
          changed: false,
          disabled: false,
          loading: false,
        },
      },
      default: {
        title: '',
        category: 'GM',
        cities: [3],
        logotype: null,
        advertisers: {}
      },
      changed: false,
      saved: null,
      disabled: false,
      confirm: false,
    }),

    computed: {
      title () {
        return (this.id==null ? 'Adicionar Marca ' : 'Gerenciar Marca ') + 
          (this.level==1 ? '> Anunciante' : this.level==2 ? '> Contrato' : '')
      },
      hasLogo () {
        return !_.isNil(this.controller.logotype.data) && !_.isEmpty(this.controller.logotype.data)
      },
      validator () {
        const data = _.clone(_.pickBy(this.controller, field => _.has(field, 'rules')));
        return _.mapValues(data, (field, f) => {
          return _.isArray(data[f].data) ? _.every(data[f].data, d => this.checkRules(field.rules, d)) : this.checkRules(field.rules, data[f].data);
        });
      },
      validated () {
        return _.every(this.validator, (field, f) => {
          return field==true;
        });
      },
      level () {
        const advertisers = this.controller.advertisers;
        return advertisers.contract!=null ? 2 : advertisers.selected!=null ? 1 : 0;
      },
      hasAdvertisers () {
        return _.size(this.controller.advertisers.data)>0;
      },
      contract () {
        const advertiser = this.controller.advertisers.selected == null ? null : this.controller.advertisers.data[this.controller.advertisers.selected]
        return advertiser==null ? null : advertiser.contracts[this.controller.advertisers.contract]
      },
      isAgency () {
        return this.user.agency!=null;
      }
    },

    watch: {
      controller: {
        deep: true,
        handler (controller) {
          const fields = _.cloneDeep(controller);
          _.each(fields, (d, f) => {
            if (!_.isEqual(this.data[f], d.data)) console.log('changed', f, this.data[f], d.data);
            this.controller[f].changed = !_.isEqual(this.data[f], d.data);
          })
          this.changed = _.some(this.controller, ['changed', true]);
          this.saved = this.changed ? false : this.saved===true ? true : null;
        }
      },
      data: {
        immediate: true,
        deep: true,
        handler (data) {
          console.log('brand data', data);
          const controller = _.isNil(data) ? this.default : 
            _.isString(data) ? _.assign({}, _.clone(this.default), { title: data }) :
            _.assign({}, _.clone(this.default), _.cloneDeep(data));
          
          if (this.changed&&this.updated) {
            this.saved = true;
          }else{
            this.saved = null;
          }

          if (this.isAgency&&_.size(controller.advertisers)==0) {
            const i = Date.now()
            controller.advertisers[i] = { 
              id: i, 
              agency: {
                id: this.user.agency,
                name: _.find(this.agencies, ['id', this.user.agency]).name
              }, 
              contracts: {}, 
              users: [] 
            }
          }

          _.each(controller, (d, f) => {
            if (_.has(this.controller, f)) {
              this.controller[f].data = d;
              this.controller[f].changed = false;
            }
          });

          if (this.controller.title=='') {
            setTimeout(($) => {
              $.$refs['title'].focus();
            }, 250, this);
          }
        }
      },
      saved (saved, before) {
        if (saved&&!before) {
          this.controller.advertisers.selected = null;
          this.controller.advertisers.contract = null;
        }
      }
    },

    methods: {
      save () {
        setTimeout($ => {  
          console.log('saveBrand', $.controller);
          const data = _.reduce(_.cloneDeep($.controller), (data, field, f) => {
            return data.id==null || field.changed ? { [f]: field.data, ...data } : data;
          }, { id: $.id });

          if (_.has(data, 'title')) data['brand_name'] = data.title
          if (_.has(data, 'discount')) data['discount'] = parseFloat(data.discount.replace(',', '.')) / 100;
          if (_.has(data, 'logotype')) {
            if (data.logotype!=null&&data.logotype!='') {
              data['logotype'] = data.logotype.replace(/^data:image\/[a-z]+;base64,/, '');
            }else{
              $.$delete(data, 'logotype');
            }
          }
          if (_.has(data, 'cities')) {
            data.licensed_cities = data.cities;
            $.$delete(data, 'cities');
          }
          if (_.has(data, 'advertisers')) {
            data.advertisers = JSON.stringify(_.reduce(data.advertisers, (advertisers, advertiser) => {
              if (_.isNil(advertiser.id)||!_.isEqual(advertiser, $.data.advertisers[advertiser.id])) {
                if (_.isNil(advertiser.id)||_.has($.data.advertisers, advertiser.id)&&!_.isEqual(advertiser.agency, $.data.advertisers[advertiser.id].agency)) {
                  const agency = _.isString(advertiser.agency) ? { id: null, name: advertiser.agency } : advertiser.agency;
                  advertiser.agency = agency;
                }
                advertiser.contracts = _.reduce(advertiser.contracts, (contracts, contract) => {
                  if (contract.id==null||!_.isEqual(contract, $.data.advertisers[advertiser.id].contracts[contract.id])) {
                    contracts.push({
                      contract_id: contract.id,
                      budget: contract.budget,
                      impressions: contract.impressions,
                      dt_begin: moment(contract.period.start, 'YYYY-MM-DD').utc().format('YYYY-MM-DD HH:mm:ss'),
                      dt_end: moment(contract.period.end, 'YYYY-MM-DD').add(1,'d').subtract(1,'s').utc().format('YYYY-MM-DD HH:mm:ss'),
                    });
                  }
                  return contracts;
                }, [])
                advertiser.advertiser_id = advertiser.id;
                advertisers.push(advertiser);
              }
              return advertisers;
            }, []))
          }

          this.confirm = false;
          this.saved = false;
          this.$emit('save', data);
        }, 250, this);
      },
      close (force, confirm) {
        if (force===true||_.isNil(this.controller.advertisers.selected)) {
          if (!this.changed||confirm===true) {
            this.controller.advertisers.selected = null;
            this.controller.advertisers.contract = null;
            this.confirm = false;
            this.$emit('toggle', false);
          }else{
            this.confirm = true;
          }
        }else{
          if (this.level==2) {
            this.selectContract(null);
          }else{
            this.selectAdvertiser(null);
          }
        }
      },

      updateField (path, value) {
        console.log(path, value);
        _.reduce(path, (field, f, i) => {
          if (path.length==i+1) field[f] = value;
          return field[f];
        }, this.controller)
      },

      checkRules (rules, value) {
        return _.every(rules, rule => {
          // console.log(value, rule(value));
          return rule(value);
        });
      },

      addAdvertiser () {
        const i = Date.now();
        const agency = this.isAgency ? {
          id: this.user.agency,
          name: _.find(this.agencies, ['id', this.user.agency]).name
        } : null;
        this.$set(this.controller.advertisers.data, i, { id: null, agency, contracts: {}, users: [] });
        this.selectAdvertiser(i);
      },
      selectAdvertiser (i) {
        console.log('select advertiser', i)
        setTimeout(($,i) => {
          $.controller.advertisers.selected = i;
        }, 500, this, i);
      },
      deleteAdvertiser (i) {
        console.log('delete advertiser', i);
        this.selectAdvertiser(null);
        setTimeout(($,i) => {
          if (_.has($.controller.advertisers.data, i)) $.$delete($.controller.advertisers.data, i);
        }, 50, this, i);
      },
      addContract () {
        const i = Date.now();
        const advertiser = this.controller.advertisers.selected;
        this.$set(this.controller.advertisers.data[advertiser].contracts, Date.now(), { id: null, budget: 5000, provisioned: 0, impressions: 50000, price: 0.10, period: { start: moment().format('YYYY-MM-DD'), end: moment().add(1,'month').format('YYYY-MM-DD') } });
        this.selectContract(i);
      },
      selectContract (i) {
        console.log('select contract', i)
        setTimeout(($,i) => {
          $.controller.advertisers.contract = i;
        }, 500, this, i);
      },
      deleteContract (i) {
        console.log('delete contract', i);
        this.selectContract(null);
        const advertiser = this.controller.advertisers.selected;
        setTimeout(($,i,advertiser) => {
          if (_.has($.controller.advertisers.data[advertiser].contracts, i)) $.$delete($.controller.advertisers.data[advertiser].contracts, i);
        }, 50, this, i, advertiser);
      },

      selectLogo (e) {
        console.log('select file');
        this.$refs['upload-field'].click();
      },
      uploadLogo (event, dropped) {
        const file = dropped ? event.dataTransfer.files[0] : event.target.files[0];
        console.log('upload file', file);

        setTimeout(($) => {
          $.toggleHighlight(false);
          $.controller.logotype.loading = true;
        }, 250, this);
        this.controller.logotype.error = null;
        
        const $ = this;
        if (_.indexOf(this.controller.logotype.format.allowed, file.type)>=0) {
          var reader = new FileReader();
          reader.onload = (e) => {
            console.log('loaded file', e.target);
            $.controller.logotype.loading = false;
            const test = new Image();
            test.addEventListener('load', (meta) => {
              $.validateLogo(file, meta.target)
            });
            test.src = e.target.result;
          };
          reader.readAsDataURL(file);
        }else{
          this.validateLogo(file);
        }
      },
      validateLogo (file, metadata) {
        let messages = []
        if (file.size>this.controller.logotype.format.max) {
          messages.push('A logo deve ter <b>até 500KB</b> de tamanho.')
        }
        if (_.indexOf(this.controller.logotype.format.allowed, file.type)==-1) {
          messages.push('Os formatos aceitos são <b>PNG</b> ou <b>SVG</b>.')
        }
        const success = this.controller.logotype.format.success = messages.length==0;
        this.controller.logotype.error = messages.length==0 ? null : _.join(messages, ' ');
        if (success) this.controller.logotype.data = metadata.src;
        this.controller.logotype.file = null;
        return success;
      },
      toggleHighlight (b) {
        this.controller.logotype.highlight = b;
      },
    },

    filters: {
      advertiserTitle (advertiser, brand) {
        return _.isNil(advertiser) ? '' : _.isNil(advertiser.agency) ? brand.title : advertiser.agency.name;
      },
      advertiserBrief (advertiser, brand) {
        let brief = '';
        const contracts = _.reduce(advertiser.contracts, (total, contract) => contract.budget+total, 0);
        brief = contracts > 0 ? `${numeral(contracts).format('$ 0[,]0 a')} em Contratos` : 'Sem contratos';
        const users = _.size(advertiser.users);
        brief = brief + ' | ' + (users > 0 ? users + ' pessoa' + (users > 1 ? 's' : '') : 'Sem pessoas');
        brief = contracts==0&&users==0 ? 'Clique aqui para gerenciar Contratos e Pessoas' : brief;
        return brief;
      }
    },

    components: {
      AdvertisersControl: () => import('@/components/AdvertisersControl'),
      ContractsControl: () => import('@/components/ContractsControl')
    },

    directives: {
      money: VMoney
    }
  }
</script>